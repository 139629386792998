import React, {FC, useCallback} from "react";
import {Button} from "@src/components/Button/Button";
import {TouristForm} from "@src/pages/Tours/TourFormalization/components/steps/Step2/TouristForm";
import style from '@src/pages/Tours/TourFormalization/components/steps/Step2/Step2.module.scss'
// TRAIN FLOW: import setStepsState4
import {setStepsState3, setStepsState4} from "@src/store/ducks/orders/reducer";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {useFormikContext} from "formik";
import * as Yup from "yup";
import {validateForm} from "@src/messages/validateForm";
import {selectUserInfo} from "@src/store/ducks/user/selectors";

// TRAIN FLOW: add vehicleType
type Step2Props = {
    vehicleType: string,
    quantity?: any;
    step?: any
    setStep?: any,
}

const validationSchema1 = Yup.object().shape({
    data: Yup.object().shape({
        tourists: Yup.array().of(Yup.object().shape({
            fio: Yup.string().required(validateForm.required),
            birthDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
            passportIssueDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
            passportExpiryDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
            passportSeriesAndNumber: Yup.string().required(validateForm.required),
            phoneNumber: Yup.string().required(validateForm.required),
        }))
    }),
    selectsArr: Yup.array().of(Yup.object().shape({
        fromCity:Yup.mixed().required(validateForm.required),
        roomType: Yup.mixed().required(validateForm.required),
        gender: Yup.mixed().required(validateForm.required),
/*        citizenCategory: Yup.mixed().required(validateForm.required),*/
    })),
})

const validationSchema2 = Yup.object().shape({
    data: Yup.object().shape({
        tourists: Yup.array().of(Yup.object().shape({
            fio: Yup.string().required(validateForm.required),
            birthDate: Yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Неверный формат даты').required(validateForm.required),
        }))
    }),
})


// TRAIN FLOW: add vehicleType
export const Step2: FC<Step2Props> = ({vehicleType, step, setStep, quantity}) => {
    const chosenUser = useSelector(selectUserInfo)
    const dispatch = useDispatch<AppDispatch>()
    // TRAIN FLOW: add setFieldValue
    const {values, setFieldError, setFieldTouched, setFieldValue}: any = useFormikContext()

    const onClickNext = useCallback(async () => {
        let count = values.selectsArr.length
        try {
            for(let i = 0; i < count; i++ ) {
                setFieldTouched(`data.tourists[${i}].fio`, true)
                setFieldTouched(`data.tourists[${i}].birthDate`, true)
                setFieldTouched(`data.tourists[${i}].passportIssueDate`, true)
                setFieldTouched(`data.tourists[${i}].passportExpiryDate`, true)
                setFieldTouched(`data.tourists[${i}].passportSeriesAndNumber`, true)
                setFieldTouched(`data.tourists[${i}].phoneNumber`, true)
                setFieldTouched(`selectsArr[${i}].fromCity`, true)
                setFieldTouched(`selectsArr[${i}].roomType`, true)
                setFieldTouched(`selectsArr[${i}].gender`, true)
                setFieldTouched(`selectsArr[${i}].citizenCategory`, true)
            }
             if (chosenUser.roleId === 2) {
                 await validationSchema1.validate(values, {abortEarly: false})
             } else {
                 await validationSchema2.validate(values, {abortEarly: false})
             }
             // TRAIN FLOW: set places if train at second step
             if(vehicleType !== 'bus') {
                 setStep(step + 2)
                 setFieldValue('data.selectedPlaces', values.selectsArr.map((el: string, i: number) => `${vehicleType}-place-${i + 1}` ))
                 // TODO: my нужен ли этот диспатч, UPD: походу надо
                 dispatch(setStepsState4(false));
             }else {
                setStep(step + 1)
                dispatch(setStepsState3(false));
             }
            //  old
            // setStep(step + 1);
        } catch (err: any) {
            err.inner.forEach((error: any) => {
                setFieldError(error.path, error.errors[0])
            })
        }
    }, [setFieldError, values])


    return (
        <div className={style['step-2']}>
            {Array.from({length: quantity}).map((_, index) => (
                <TouristForm namePart={`data.tourists[${index}]`} nameSelectArr={`selectsArr[${index}]`} key={index}
                             num={index + 1}/>
            ))}
            <Button onClickFunc={onClickNext} className={'btn-second'}>Следующий шаг</Button>
        </div>
    )
}
