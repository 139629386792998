import React, {FC, useState} from "react";
import style from "@src/pages/History/HistoryDetails/HistoryDetails.module.scss"
import {Calendar} from "@src/components/AllSvg/Calendar";
import {OrdersHistoryElemType} from "@src/store/ducks/orders/types";
import {FieldHistory} from "@src/pages/History/HistoryDetails/FieldHistory";
import {HistoryOrderElem} from "@src/pages/History/HistoryOrderElem/HistoryOrderElem";
import {Button} from "@src/components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectSourceRegirstration} from "@src/store/ducks/orders/selectors";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {getVehicleTypeFromVehicleSlug} from "@src/customFunctions/General";
import {HistoryChange} from "@src/pages/History/HistoryChange/HistoryChange";
import {HistoryPlaceChange} from "@src/pages/History/HistoryPlacesChange/HistoryPlaceChange";
import {HistoryPlaceChangeForm} from "@src/pages/History/HistoryPlacesChange/HistoryPlaceChangeForm";
import {getBusPlacesAsinc, getHistoryNewArrCityAsync} from "@src/store/ducks/orders/thunks";
import {AppDispatch} from "@src/store/store";

type TableHistoryElemProps = {
    data: OrdersHistoryElemType,
    setShowElem: any
};

export const HistoryDetails: FC<TableHistoryElemProps> = ({data, setShowElem}) => {
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector(selectUserInfo)
    const [changedUser, setChangedUser] = useState<number | null>(null);
    const [showChosePlaces, setShowChosePlaces] = useState(false);

    const dataOrder = {
        startDate: data.startDate,
        endDate: data.endDate,
        applicationDate: data.applicationDate,
        sourceRegistration: data.sourceRegistration,
        additionalExcursions: data.additionalExcursions,
        title: data.tour.title,
        currency: data.trip.currency,
        finalFirstPartPriceByn: data.finalFirstPartPriceByn,
        finalSecondPartPriceByn: data.finalSecondPartPriceByn,
        finalPriceUsd: data.finalPriceUsd,
        busSlug: data.busSlug
    }

    const sourceRegistrationFromApi = useSelector(selectSourceRegirstration)
    const sourceRegistration = data.sourceRegistration

    const vehicleType = getVehicleTypeFromVehicleSlug(data.busSlug)

    return (
        <div className={style['history-details']}>
            <div className={style['final-order']}>
                <div className={style.title}>
                    Итоговые данные заявки
                </div>
                <div className={style['final-order-titleBlock']}>
                    <div className={style['tour-date']}>
                        <Calendar/>
                        <span>{dataOrder.startDate}</span> - <span>{dataOrder.endDate}</span>
                    </div>
                    <div className={style['tour-title']}>
                        {dataOrder.title}
                    </div>
                </div>
                {changedUser || changedUser === 0 ?
                    <HistoryChange changedData={data?.tourists[changedUser]} places={data?.places[changedUser]} orderId={data.id} changedUser={changedUser}
                                   vehicleType={vehicleType} setChangedUser={setChangedUser} cityArr = {data.citiesArrHistory? data.citiesArrHistory : null }/> : null }
                <div className={style['final-order-list']}>
                    {data?.tourists.map((item: any, index: number,array) => {
                        return index !== changedUser &&
                            <HistoryOrderElem key={index} data={item} index={index} places={data?.places[index]}
                                              vehicleType={vehicleType} setChangedUser={setChangedUser} orderId={data.id} changedUser={changedUser} arrayLength={array.length}/>
                    })}
                </div>


                {data.busId && data.busId !== 6 && <div className={style.changePlacesBlock}>
                    {showChosePlaces && <HistoryPlaceChangeForm data={data} setShowChosePlaces={setShowChosePlaces}/>}
                    {!showChosePlaces && <Button className={'btn-second'} onClickFunc={() => {
                        setShowChosePlaces(true)
                        dispatch(getBusPlacesAsinc(data.trip.id, data.id, true))
                    }}>Выбор места</Button>}
                </div>
                }
                <div className={style.additional}>
                    <div className={style.title}>
                        Дополнительные сведения
                    </div>
                    <div className={style['additional-wrap']}>
                        <FieldHistory text={dataOrder.applicationDate}
                                      label='Дата заявки' svg={true}/>
                        <FieldHistory
                            text={dataOrder.sourceRegistration ? sourceRegistrationFromApi[sourceRegistration] : ''}
                            label='Путь оформления'/>
                    </div>
                    <FieldHistory text={dataOrder.additionalExcursions} label={'Дополнительные экскурсии'}
                                  className={style.textarea}/>
                    <div className={style.paymentBlock}>
                        <div className={style['paymentBlock-title']}>
                            Данные об оплате
                        </div>
                        <div className={style['paymentBlock-wrapper']}>
                            <div className={style.firstBlock}>
                                <FieldHistory text={dataOrder.finalFirstPartPriceByn}
                                              type={'number'}
                                              label='Стоимость услуги (Первый взнос)'/>
                                {user.roleId === 2 &&
                                    <div className={style.message}>Сумма указана за вычетом комиссии</div>}
                            </div>
                            <FieldHistory text={dataOrder.finalSecondPartPriceByn}
                                          type={'number'}
                                          label='Стоимость услуги (Второй взнос)'/>
                            <FieldHistory text={dataOrder.finalPriceUsd}
                                          type={'number'}
                                          label='Валютная часть'
                                          currency={dataOrder.currency}
                            />
                        </div>
                    </div>
                    <Button onClickFunc={() => setShowElem(false)} className={'btn-second'}>Скрыть детали
                        заказа</Button>
                </div>
            </div>
        </div>
    )
}


